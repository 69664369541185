<template>
  <div class="notifications">
    <h1>Centre de notifications</h1>
    <h2 class="notifications-h2" v-if="getAlerts.length === 0">
      Vous n'avez pas de notification
    </h2>
    <h2 class="notifications-h2" v-else>
      Vous avez {{ getAlerts.length }} notifications
    </h2>
    <div class="notifications-pretrade" v-if="returnPretradeAlerts">
      <el-table
        :data="returnPretradeAlerts"
        style="width: 95%"
        :row-class-name="tableRowClassName"
        @selection-change="checkboxChange"
      >
        <el-table-column type="selection" width="40" />
        <el-table-column
          fixed="left"
          label="Actions"
          width="90"
          v-if="checkboxSelection.length === 0"
        >
          <template #default="scope">
            <div v-if="scope.row.action === null">
              <el-button
                color="#2A8A82"
                size="small"
                :icon="Check"
                circle
                @click="openConfirmDeclineDialog(1, scope.row)"
              ></el-button>
              <el-button
                type="danger"
                size="small"
                :icon="Close"
                circle
                @click="openConfirmDeclineDialog(0, scope.row)"
              ></el-button>
            </div>
            <div v-else>
              <el-button
                type="info"
                size="small"
                :icon="RefreshLeft"
                circle
                @click="cancelResponse(2, scope.row)"
              ></el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Ticker" width="120">
          <template #default="scope">
            {{ scope.row.ticker }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="Date" />
        <el-table-column label="Prix" width="120">
          <template #default="scope"> {{ scope.row.price / 100 }} € </template>
        </el-table-column>
        <el-table-column label="Frais de port" width="130">
          ---- €
        </el-table-column>
        <el-table-column label="Pays d'expédition" width="170">
          ----
        </el-table-column>
        <el-table-column prop="time_limit" label="Reminder" />
        <el-table-column width="120">
          <template #default="scope">
            <el-button link type="info" @click="previewProposal(scope.row)">
              Details
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="actions-lists" v-if="activeActionsList">
        <el-button color="#2A8A82" @click="openConfirmDeclineDialog(1)"
          >ACCEPTER LA SELECTION</el-button
        >
        <el-button type="danger" @click="openConfirmDeclineDialog(0)"
          >DECLINER LA SELECTION</el-button
        >
      </div>
      <div class="payment-process" v-if="paymentEnabled">
        <el-button color="#2A8A82"> Procéder au paiement </el-button>
      </div>
      <el-dialog v-model="confirmDeclineDialog">
        <p v-if="typeAction === 0" style="margin-bottom: 20px">
          Choisissez une raison pour décliner l'offre
        </p>
        <el-select
          v-model="reasonSelected"
          multiple
          placeholder="Select"
          style="width: 60%"
          v-if="typeAction === 0"
        >
          <el-option
            v-for="item in getLists.decline_reason"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <p v-if="typeAction === 1">
          Etes-vous sur de vouloir accepter l'offre ?
        </p>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="confirmDeclineDialog = false" type="info"
              >Annuler</el-button
            >
            <el-button
              color="#2A8A82"
              @click="sendResponse()"
              :disabled="reasonSelected.length < 1 && typeAction === 0"
            >
              Continuer
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script setup>
import { Check, Close, RefreshLeft } from '@element-plus/icons-vue'
</script>

<script>
import { mapGetters, mapActions } from 'vuex'
import { filter } from 'lodash'

export default {
  data() {
    return {
      checkboxSelection: [],
      activeActionsList: false,

      confirmDeclineDialog: false,
      typeAction: null,
      rowAction: null,

      reasonSelected: [],

      paymentEnabled: false,
    }
  },
  watch: {
    checkboxSelection(nV) {
      if (nV.length > 0) {
        this.activeActionsList = true
      } else {
        this.activeActionsList = false
      }
    },
    getAlerts() {
      const acceptPretrade = filter(this.getAlerts, function (o) {
        return o.action === 1
      })

      if (acceptPretrade.length > 0) {
        this.paymentEnabled = true
      } else {
        this.paymentEnabled = false
      }
    },
  },
  computed: {
    ...mapGetters({
      getAlerts: 'getAlerts',
      getLists: 'getLists',
    }),
    returnPretradeAlerts() {
      return filter(this.getAlerts, function (o) {
        return o.alert_type === 1
      })
    },
  },
  methods: {
    ...mapActions({
      sendResponseActions: 'sendResponseAction',
      getAlertsActions: 'getAlerts',
    }),
    previewProposal(pretrade) {
      this.$store.commit('SET_PRETRADE_INFORMATIONS', pretrade)
      this.$router.push('/auth/pretrade')
    },
    checkboxChange(selection) {
      this.checkboxSelection = selection
    },
    openConfirmDeclineDialog(type, row) {
      this.typeAction = type
      this.rowAction = row
      this.confirmDeclineDialog = true
    },
    async sendResponse() {
      if (this.checkboxSelection.length > 0) {
        this.checkboxSelection.forEach(async (pretrade) => {
          const payload = {
            action: this.typeAction,
            pretrade_uuid: pretrade.pretrade_uuid,
            reason: this.typeAction === 1 ? null : this.reasonSelected,
          }

          await this.sendResponseActions(payload).then(() => {
            this.reset()
          })
        })
      } else {
        const payload = {
          action: this.typeAction,
          pretrade_uuid: this.rowAction.pretrade_uuid,
          reason: this.reasonSelected,
        }
        await this.sendResponseActions(payload).then(() => {
          this.reset()
        })
      }
    },
    async cancelResponse(type, row) {
      const payload = {
        action: type,
        pretrade_uuid: row.pretrade_uuid,
      }
      await this.sendResponseActions(payload).then(() => {
        this.reset()
      })
    },
    tableRowClassName(row) {
      if (row.row.action === 0) {
        return 'accept-row'
      } else if (row.row.action === 1) {
        return 'decline-row'
      }
    },
    reset() {
      this.reasonSelected = []
      this.confirmDeclineDialog = false
      this.getAlertsActions()
    },
  },
}
</script>

<style lang="scss">
.notifications-pretrade {
  display: flex;
}

.notifications-pretrade {
  justify-content: center;
}

.notifications-h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-top: 5px;
}

.notifications-pretrade {
  flex-wrap: wrap;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
}

.actions-lists,
.payment-process {
  margin-top: 20px;
}

.el-table .accept-row {
  --el-table-tr-bg-color: var(--el-color-danger-light-9);
}

.el-table .decline-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
